<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <div class="">
      <v-row>
        <v-col cols="12">
          <div class="table-responsive">
            <table
              class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Email</th>
                  <th scope="col">Question</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(ticket, i) in support_tickets">
                  <tr :key="i">
                    <td scope="row">{{ i + 1 }}</td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ ticket.email }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ ticket.question }}
                      </p>
                    </td>
                    <td>
                      <div style="display: flex">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-success btn-sm"
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialogAswer(ticket)"
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color=""
                                  >mdi-frequently-asked-questions</v-icon
                                >
                              </span>
                            </button>
                          </template>
                          <span>Trả lời</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="!support_tickets.length">
                  <tr>
                    <td style="text-align: center" colspan="4">No data</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogAnswerTicket"
        max-width="1200px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Trả lời câu hỏi thắc mắc</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAnswerTicket = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Nhập câu trả lời</label>
                  <Editor v-model="answer_input"></Editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnSendAnswer"
            >
              Submit
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAnswerTicket = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";

export default {
  name: "SupportTicket",
  components: {
    Loading: () => import("vue-loading-overlay"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  data() {
    return {
      is_call_api: false,
      support_tickets: [],
      ticket_id: null,
      dialogAnswerTicket: false,
      answer_input: "",
    };
  },
  async created() {
    this.is_call_api = true;
    await this.getSupportTickets();
    this.is_call_api = false;
  },
  methods: {
    async getSupportTickets() {
      let vm = this;
      try {
        let res = await ApiService.get("prep-app/faq-category/support-ticket");
        if (res.status === 200) {
          vm.support_tickets = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDialogAswer(ticket) {
      this.ticket_id = ticket.id;
      (this.answer_input = ticket.answer == null ? "" : ticket.answer),
        (this.dialogAnswerTicket = true);
    },
    btnSendAnswer() {
      if (this.answer_input === "" || this.answer_input == null) {
        this.$toasted.error("Câu trả lời không được bỏ trống !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let vm = this;
      let data = {
        answer: this.answer_input,
      };
      Swal.fire({
        title: "Bạn chắc chắn gửi câu trả lời này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Chắc chắn!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/faq-category/support-ticket/" + vm.ticket_id,
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.is_call_api = false;
                vm.dialogAnswerTicket = false;
                vm.$toasted.success("Gửi câu trả lời thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.dialogAnswerTicket = false;
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
